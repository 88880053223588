<template>
    <v-card outlined>
        <v-card-text>
            <v-row wrap ro align="center">
                <v-col cols="12">
                    <form autocomplete="nope" @submit.prevent="postData">
                        <v-container grid-list-md fluid>
                            <v-row dense align="center">
                                <v-col cols="6">
                                    <v-autocomplete
                                        dense
                                        v-model="servicio"
                                        v-validate="'required'"
                                        label="Producto"
                                        :items="selectCortesia"
                                        data-vv-as="marca"
                                        :error-messages="errors.collect('servicio')"
                                        data-vv-name="servicio"
                                        required
                                        item-text="description"
                                        item-value="id"
                                        return-object
                                    >
                                        <template v-slot:item="data">
                                            <v-chip small color="secondaryTheme" class="caption" dark>{{ data.item.category }}</v-chip>
                                            <span class="caption">{{ data.item.description }} - S/ {{ data.item.price }}</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-autocomplete dense v-model="quantity" :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" label="Unidades" />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field dense v-model="itemTotalProduct" prefix="S/." filled rounded label="Sub total" readonly />
                                </v-col>
                                <v-col cols="12" sm="2" class="text-center">
                                    <v-btn block color="secondaryTheme" dark type="submit">agregar</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </form>
                </v-col>
                <v-col cols="12" class="grey lighten-5">
                    <v-data-table multi-sort :headers="headers" :items="listaServicios" class="elevation-1" :items-per-page="20" item-key="timestamp">
                        <template v-slot:[`item.action`]="{ item }">
                            <v-icon color="primary" small @click="deleteItem(item)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { format } from 'date-fns'
import DefaultForm from '@/utils/defaultForm'

export default {
    filters: {},
    props: ['lista', 'service'],
    $_veeValidate: {
        validator: 'new'
    },
    data: () => ({
        servicio: DefaultForm.formSelectServicio(),
        isSelect: false,
        quantity: 1,
        time: format(new Date(), 'HH:mm'),
        listaServicios: [],
        headers: [
            { text: 'Actions', value: 'action', sortable: false },
            { text: 'Descripción', value: 'description' },
            { text: 'Categoria', value: 'category' },
            { text: 'P. UNIT (S/)', value: 'price' },
            { text: 'UNID', value: 'unidades' },
            { text: 'SUBTOTAL', value: 'subTotal' }
        ]
    }),
    computed: {
        ...mapState('ventas', ['datos']),
        ...mapState('category', ['genericUtils']),
        ...mapGetters('productos', ['listaCortesiaOut']),
        itemTotalProduct() {
            if (this.servicio) {
                return parseFloat(this.servicio.price) * parseFloat(this.quantity)
            }
            return null
        },
        selectCortesia() {
            let listaCortesiaOut = []
            if (this.listaCortesiaOut && this.genericUtils) {
                this.listaCortesiaOut.map((v) => {
                    const category = this.genericUtils.find((x) => x.id === v.id_category)
                    listaCortesiaOut.push({
                        ...v,
                        category: category.name,
                        comision: 0,
                        descuentos: 0,
                        unidades: 1
                    })
                })
            }
            return listaCortesiaOut
        }
    },

    methods: {
        postData() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    this.listaServicios.push({
                        ...this.servicio,
                        unidades: this.quantity,
                        subTotal: this.itemTotalProduct
                    })
                    this.$store.commit('ventas/UPDATE_CORTESIA', this.listaServicios)
                    this.servicio = DefaultForm.formSelectServicio()
                }
            })
        }
    }
}
</script>
<style></style>
